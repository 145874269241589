<template>
  <div class="plan-trouble">
    <van-nav-bar left-arrow title="选择改善" @click-left="$router.back()">
      <template #right>
        <van-icon
          class="create-icon"
          name="xinzengfengxian"
          class-prefix="iconfont"
          @click.native="onCreate"
        />
      </template>
    </van-nav-bar>
    <van-search
      v-model="queryInfo.name"
      class="search-bar"
      style="padding-bottom: 12px"
      placeholder="请输入改善名称关键字"
      @clear="onRefresh"
      @search="onRefresh"
    >
      <template #left-icon>
        <van-icon name="sousuo" class-prefix="iconfont" class="search-icon" />
      </template>
    </van-search>

    <date-time
      v-model="dateTime"
      :time-close="false"
      type="date"
      style="margin-top: 0; margin-bottom: 6px"
    />

    <div class="search-query">
      <trouble-query :query="queryInfo" @refresh="onQueryChange" />
      <div class="trouble-total">共{{ total }}处改善</div>
    </div>
    <van-pull-refresh
      v-if="list.length"
      v-model="isPullRefreshing"
      class="plan-trouble__wrapper plan-trouble__wrapper--select"
      @refresh="onRefresh"
    >
      <van-checkbox-group v-model="troubleCheckedIds" checked-color="#1989fa">
        <van-list
          v-model="isLoading"
          :finished="isFinished"
          :finished-text="isError ? '' : '没有更多了'"
          :error.sync="isError"
          error-text="加载失败，请稍后再试！"
          @load="getList"
        >
          <van-cell
            v-for="(item, idx) in list"
            :key="idx"
            :class="[
              'plan-trouble__rows',
              idx === list.length - 1 && 'border-none'
            ]"
            :border="false"
          >
            <van-checkbox :name="item.id" shape="square">
              <div class="plan-trouble__left">
                <p class="title">
                  <span>
                    {{ item.troubleLevelName || "--" }} -
                    {{ item.name || "--" }}
                  </span>
                </p>
                <div class="field">
                  <div class="field-item">
                    <van-icon
                      class-prefix="iconfont"
                      class="list-icon"
                      color="#aeb3c0"
                      name="chejianmingcheng"
                    />
                    <span>{{ item.departmentName || "--" }}</span>
                  </div>
                  <div class="field-item">
                    <van-icon
                      class-prefix="iconfont"
                      class="list-icon"
                      color="#aeb3c0"
                      name="quyu1"
                    />
                    <span>{{ item.poiName || "--" }}</span>
                  </div>
                  <div class="field-item">
                    <van-icon
                      class-prefix="iconfont"
                      class="list-icon"
                      color="#aeb3c0"
                      name="renyuan"
                    />
                    <span>{{ item.discovererName || "--" }}</span>
                  </div>
                  <div class="field-item">
                    <van-icon
                      class-prefix="iconfont"
                      class="list-icon"
                      color="#aeb3c0"
                      name="jianchaleixing"
                    />
                    <span>{{ item.discoveryName || "--" }}</span>
                  </div>
                  <div class="field-item">
                    <van-icon
                      class-prefix="iconfont"
                      class="list-icon"
                      color="#aeb3c0"
                      name="yinhuanleixing"
                    />
                    <span>{{ item.disposeStatusName || "--" }}</span>
                  </div>
                </div>
                <div class="time">
                  <van-icon
                    class-prefix="iconfont"
                    class="list-icon"
                    color="#aeb3c0"
                    name="ico-timerange"
                  />
                  <span>{{ item.discoverDate }}</span>
                </div>
              </div>
              <div class="plan-trouble__right">
                <van-icon
                  :name="troubleStatusName(item.disposeStatus)"
                  :color="troubleStatusColor(item.disposeStatus)"
                  class-prefix="iconfont"
                  class="trouble-status-icon"
                />
              </div>
            </van-checkbox>
          </van-cell>
        </van-list>
      </van-checkbox-group>
      <div class="details-page__footer-button-wrapper--fixed">
        <van-button block round type="info" @click="onConfirm">确定</van-button>
      </div>
    </van-pull-refresh>
    <box-empty
      v-else
      style="height: calc(100vh - 58px - 66px - 38px - 40px - 42px)"
    ></box-empty>
  </div>
</template>

<script>
import BoxEmpty from "@/components/BoxEmpty";
import { getTroubleList } from "@/api/improve/improve";
import "./styles/trouble-list.scss";
import { TROUBLE_ICON_STATUS } from "@/views/trouble/utils/constant";
import { operateDialog } from "@/utils";
import TroubleQuery from "./components/TroubleQuery";
import cloneDeep from "lodash/cloneDeep";
import { mapState, createNamespacedHelpers } from "vuex";
const {
  mapActions,
  mapMutations,
  mapState: improvePlanState
} = createNamespacedHelpers("improvePlan");

export default {
  name: "SelectTrouble",
  components: { TroubleQuery, BoxEmpty },
  props: ["planId", "id"],
  data() {
    return {
      queryInfo: {
        planId: "",
        categoryId: "",
        troubleLevel: "",
        disposeStatus: "",
        disposeStatusStr: "1,2,3,4,5,7",
        notRelatePlan: 1,
        nonTroubleIds: "",
        name: "",
        classifyId: "",
        page: 1,
        size: 10
      },
      dateTime: {
        start: "",
        end: ""
      },
      total: 0,
      list: [],
      // 下拉刷新，上拉加载
      isPullRefreshing: false,
      isLoading: false,
      isFinished: true,
      isError: false,
      troubleCheckedIds: [],
      itemIndex: 0
    };
  },
  computed: {
    ...mapState({
      userInfo: state => state.login.userInfo
    }),
    ...improvePlanState({
      checkItemsStore: state => state.checkItems,
      selectedTroubleIds: state => state.selectedTroubleIds
    }),
    troubleStatusName() {
      return function(status) {
        status = String(status);
        if (status === "null" || !status) {
          return "";
        }
        return TROUBLE_ICON_STATUS[status]["iconName"];
      };
    },
    troubleStatusColor() {
      return function(status) {
        status = String(status);
        if (status === "null" || !status) {
          return "";
        }
        return TROUBLE_ICON_STATUS[status]["iconColor"];
      };
    }
  },
  watch: {
    dateTime: {
      handler(val) {
        if (val.start && val.end) {
          this.queryInfo.startDate = val.start + " 00:00:00";
          this.queryInfo.endDate = val.end + " 23:59:59";
          this.onRefresh();
        }
        if (!val.start && !val.end) {
          this.queryInfo.startDate = "";
          this.queryInfo.endDate = "";
          this.onRefresh();
        }
      },
      deep: true
      // immediate: true
    }
  },
  activated() {
    this.itemIndex = this.$route.query.itemIndex || 0;
    // const startVal = parseTime(Date.now() - ONE_DAY_MS * 365, "{y}-{m}-{d}");
    // const endVal = parseTime(Date.now(), "{y}-{m}-{d}");
    // this.$set(this.dateTime, "start", startVal);
    // this.$set(this.dateTime, "end", endVal);
    this.onRefresh();
  },
  deactivated() {
    this.troubleCheckedIds = [];
    this.dateTime.start = "";
    this.dateTime.end = "";
    this.queryInfo.startDate = "";
    this.queryInfo.endDate = "";
  },
  methods: {
    ...mapActions(["updateCheckItems", "asyncTroubleIds"]),
    ...mapMutations(["setTroubleIds"]),
    onCreate() {
      this.$router.push({
        name: "ImproveCreateTrouble",
        params: { planId: this.planId }
      });
    },
    onConfirm() {
      console.log(this.troubleCheckedIds, "troubleCheckedIds");
      const checkItems = cloneDeep(this.checkItemsStore);
      console.log(checkItems, "checkItems", this.checkItemsStore);
      const set = new Set();
      const current = checkItems[this.itemIndex];
      const troubleCheckedIds = (current.troubleIdArr || []).concat(
        this.troubleCheckedIds
      );
      troubleCheckedIds.forEach(x => set.add(x + ""));
      current.troubleIdArr = [...set];
      this.updateCheckItems(checkItems);
      this.$router.back();
    },
    onQueryChange(query) {
      this.queryInfo = { ...this.queryInfo, ...query };
      this.onRefresh();
    },
    onRefresh() {
      this.queryInfo.page = 1;
      this.list = [];
      this.getList();
    },
    async getList() {
      this.isFinished = false;
      this.isLoading = true;
      if (this.isPullRefreshing) {
        this.isPullRefreshing = false;
      }
      try {
        this.queryInfo.nonTroubleIds = this.selectedTroubleIds.join();
        const { list, total } = await getTroubleList(this.queryInfo);
        this.total = total;
        this.isError = false;
        this.isLoading = false;
        if (list && list.length) {
          this.list = this.list.concat(list);
          this.queryInfo.page++;
        } else {
          this.isFinished = true;
        }
      } catch (error) {
        console.log("getList -> error", error);
        this.isLoading = false;
        this.isFinished = true;
        this.isError = true;
      }
    },
    async beforeClose({ position, instance }) {
      try {
        if (position === "right") {
          await operateDialog({
            title: "",
            message: "确实删除此关联改善？删除后将解除改善与计划关联。"
          });
          instance.close();
        }
      } catch (e) {
        console.log(e);
      }
    }
  }
};
</script>
<style lang="scss">
.plan-trouble {
  .create-icon {
    font-size: 22px;
    color: #fff;
    font-weight: bold;
  }
  .trouble-total {
    text-align: center;
    color: #8c8f97;
    font-size: 14px;
    margin: 14px 0;
  }
  .plan-trouble__wrapper--select {
    height: calc(100vh - 58px - 66px - 38px - 40px - 42px - 74px);
  }
  .van-checkbox {
    position: relative;
  }
  .van-checkbox__icon.van-checkbox__icon--square {
    position: absolute;
    top: 0;
    left: 0;
  }
  .van-checkbox__label {
    margin-left: 0;
    display: flex;
  }
  .plan-trouble__left {
    .title {
      margin-left: 28px;
    }
  }
}
</style>
